import logo_Red from './Logo_Red.png';
import logo_Beige from './LogoBeige.png';
import exp_park from './exp_park.png';
import { RiWhatsappFill } from "react-icons/ri";
import { IoMdCall } from "react-icons/io";
import { MdEmail } from "react-icons/md";
import './App.css';

function App() {
  return (
    <div className="App">
        <div className="header">
            <img src={logo_Red}></img> 
        </div>
        
        <div className="horizontal line"></div>

        <div className="tagline">
          you dream, <span className='bold_tagline'>we</span> design.
        </div>

        <div className="main">

            <div className="left">
                <div className="left_box">
                  <div className='left_box_heading'>
                    <div>website</div> 
                    <div className='left_bold'>under</div>
                    <div className='left_bold'>construction.</div>
                  </div>

                  <div className='left_box_line'></div>

                  <div className='left_box_text'>We are currently working on our website. <br />
                  Stay tuned for the full experience.</div>
                  
                </div>
            </div>

            <div className="right">
              <div className="right_box_upper">
                <img src={exp_park}></img> 
                <div>COMING SOON</div>
              </div>
              
              <div className="right_box_lower">
                  <div className="right_box_lower_content">
                    <div className="icons">
                      <a href="https://wa.me/919100981008" target='_blank'> 
                        <RiWhatsappFill className='footer_icon' color='#BF3430'/> 
                      </a>
                      <a href="tel:+919100981008"> 
                        <IoMdCall color='#BF3430' className='footer_icon'/> 
                      </a>
                      <a href="mailto:kartdesignsoffice@gmail.com" target='_blank'> 
                        <MdEmail color='#BF3430' className='footer_icon'/> 
                      </a>
                    </div>
                    <div className="contact_text">
                       Contact us
                    </div>
                  </div>
              </div>
            </div>
        </div>

        <div className="footer">

          <div className="footer_main">
            <div className="footer_child_img">
              <img src={logo_Beige}></img>
               
              <div>you dream, <span className='bold_tagline'>we</span> design.</div>
            </div>

            <div className="footer_child">
              Show apartment <br />
              B-Tower 1st Floor - <br />
              Ramky One Symphony, <br className='mobile_br'/> Patancheru (ORR Exit 3)
            </div>

            <div className="footer_child">
            
              Experience park address: <br />
              14-224, Agarwal Estate, Survey <br className='mobile_br'/> No. 849, <br className='mobile_br_hide'/>Patancheru, <br className='mobile_br'/>Hyderabad, <br/> Telangana, 502319, India.
            </div>

            <div className="footer_child_contact">
              
              <a href="https://wa.me/919100981008" target='_blank'> 
                <RiWhatsappFill className='footer_icon' color='#E8DCCD'/>  
              </a>
              <a href="tel:+919100981008"> 
                <IoMdCall color='#E8DCCD' className='footer_icon'/> 
              </a>
              <a href="mailto:kartdesignsoffice@gmail.com" target='_blank'> 
                <MdEmail color='#E8DCCD' className='footer_icon'/> 
              </a>
            </div>
            
            
          </div>


          <div className='footer_hr'></div>

          <div className="footer_legal">
            
            ©2024 All rights reserved.
          </div>
          
        </div>


    </div>
  );
}

export default App;
